<template>
  <div class="layout">
    <nav>
      <ul>
        <li><router-link to="/">Main</router-link></li>
        <li><router-link to="/cv">CV</router-link></li>
      </ul>
    </nav>
    <router-view></router-view>
  </div>
  
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App'
}
</script>

<style>
:root {
  --font-main: 'Asap', Helvetica, Arial, sans-serif;
  --font-title: 'Roboto Mono', Helvetica, Arial, sans-serif;
  --main-font-s: 18px;
  --main-font-h: 1.5;
  --h1-size: 48px;
  --h1-line: 1.5;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --body-color: transparent;
  --border-color: #292A2B;
  --main-color: #292A2B;
  --container-width: 100%;
  --fa-secondary-opacity: .4;
  --lnk-color-h: 329;
  --lnk-color-s: 100%;
  --lnk-color-l: 70%; 
}
@media (prefers-color-scheme: dark) {
  :root {
    --body-color: #292A2B;
    --border-color: #fff;
    --main-color: #e6e6e6;
    --lnk-color-h: 329;
    --lnk-color-s: 100%;
    --lnk-color-l: 70%;  
    --secondary-color-h: 33;
    --secondary-color-s: 100%;
    --secondary-color-l: 68%; 
  }
  body {
    font-size: var(--main-font-s);
    line-height: var(--main-font-h);
    background-color: var(--body-color)
  }
}
@import url('https://fonts.googleapis.com/css2?family=Asap:wght@400;600&family=Roboto+Mono:wght@400;600&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  font-size: var(--main-font-s);
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul[class],
ol[class] {
  list-style: none;
}
p:not(:last-child) {
  margin-bottom: 16px;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}
body {
  font-family: var(--font-main);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-color);
}
h1 {
  font-family: var(--font-title);
  font-size: var(--h1-size);
  line-height: var(--h1-line);
  font-weight: var(--font-weight-normal);
}
h2 {
  font-family: var(--font-title);
  font-size: calc(var(--h1-size)/2);
  line-height: var(--h1-line);
  font-weight: var(--font-weight-normal);
  /* color: hsla(var(--secondary-color-h), var(--secondary-color-s), var(--secondary-color-l), 1); */
}
p {
  font-size: var(--main-font-s);
  line-height: calc(var(--main-font-s)*1.5);
}
a, a:visited {
  color: hsla(var(--lnk-color-h), var(--lnk-color-s), var(--lnk-color-l), 1);
}
a:hover {
  color: hsla(var(--lnk-color-h), var(--lnk-color-s), var(--lnk-color-l), .8);
}
a:active, a:focus {
  color: hsla(var(--lnk-color-h), var(--lnk-color-s), var(--lnk-color-l), .65);
}
.container {
  width: var(--container-w);
  margin: 0 auto;
  padding-right: 24px;
  padding-left: 24px;
}
.logo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 100px;
  border: 4px solid var(--border-color);
  display: block;
  margin: 0 auto;
}
.fa-secondary {
  opacity: var(--fa-secondary-opacity);
}
.layout {
  display: grid;
  min-height: 100vh;
}
@media (min-width: 576px) {
  :root {
    --container-w: 540px;
  }
}
@media (min-width: 768px) {
  :root {
    --container-w: 720px;
  }
}
@media (min-width: 992px) {
  :root {
    --container-w: 960px;
  }
}
@media (min-width: 1200px) {
  :root {
    --container-w: 1140px;
  }
}
/* @media (min-width: 1400px) {
  :root {
    --container-w: 1200px;
  }
} */
</style>
