<template>
  <header>
    <div class="container">
      <img width="120" height="120" alt="Artem Komyza" src="../assets/artem-photo.jpg" class="logo">
      <h1>Artem Komyza</h1>
      <h4 class="subtitle">Freelancer, Front-end developer</h4>
    </div>
  </header>
  <main class="content">
    <section class="main-section">
      <div class="container">
        <p>Hello, my name is Artem.</p>
        <p>I'm an experienced front-end developer with more than ten years of experience in working with US companies. I specialized in UX/UI-design, Vue.js, Twitter-Bootstrap, WordPress, jQuery.</p>
        <p>I make pixel-perfect PSD-to-HTML projects, responsive, mobile-friendly designs (media queries). My works maintain different browser compatibilities: IE8-IE11, Firefox 3.5+, Chrome, Safari. Also, I test the projects on IOS and Android devices (iPhone, iPad, Android mobile, Android tablet, etc.). Semantic, well-organized code - W3C Valid.</p>
        <p>I'm also using react.js, angular.js in my practice but the main foucus is on the Vue.js. Also, I have experience in using GIT, Bitbucket, Slack, Trello, Zeppelin, and working in a team.</p>
      </div>
    </section>
  </main>
  <footer>
    <div class="container">
      <h2 class="section-title">Contacts</h2>
      <ul class="contact-list">
        <li>
          <svg height="20px" alt="email" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="at" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon icon_email"><g class="fa-group"><path fill="currentColor" d="M440 232c0-102.38-83.86-160-184-160-101.46 0-184 82.54-184 184s82.54 184 184 184a184.46 184.46 0 0 0 99.41-29.13 23.94 23.94 0 0 1 31.37 5.13L397 428.4a24.94 24.94 0 0 1 1.61 2.21 24 24 0 0 1-7.2 33.17A248.76 248.76 0 0 1 256 504C118.92 504 8 393.08 8 256S118.94 8 256 8c138 0 248 87.65 248 224 0 68.32-33.63 133.22-120 145.37v-62c35.72-5.27 56-45.37 56-83.37z" class="fa-secondary"></path><path fill="currentColor" d="M391.79 164.58a24 24 0 0 0-19-28.14 24.26 24.26 0 0 0-4.58-.44h-45a13.52 13.52 0 0 0-13.43 12v.09c-14.69-17.9-40.45-21.77-60-21.77-74.55 0-137.8 62.22-137.8 151.45 0 65.3 36.79 105.87 96 105.87 27 0 57.37-15.64 75-38.33 9.52 34.1 40.61 34.1 70.71 34.1a217.39 217.39 0 0 0 30.29-2v-62c-.72.1-1.44.22-2.17.3-17.35-.45-16.91-12.85-13.48-30zM234.32 312.43c-22.25 0-36.07-15.62-36.07-40.77 0-45 30.78-72.73 58.63-72.73 22.29 0 35.6 15.24 35.6 40.77 0 45.06-33.87 72.73-58.16 72.73z" class="fa-primary"></path></g></svg> <a href="mailto:artem.kom@gmail.com">artem.kom@gmail.com</a>
        </li>
        <li>
          <svg height="20px" alt="Twitter" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-twitter fa-w-16"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" class=""></path></svg> <a href="https://twitter.com/art_kom">art_kom</a>
        </li>
        <li>
          <svg height="20px" alt="GitHub" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" class="svg-inline--fa fa-github fa-w-16"><path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" class=""></path></svg> <a href="https://github.com/artkom">artkom</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Home',
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  text-align: center;
}
h2 {
  text-align: center;
  margin-top: 0;
}
.section-title {
  color: #fff;
  text-transform: uppercase;
}
.subtitle {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
h3 {
  margin: 40px 0 0;
}
.contact-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* background-color: hsla(var(--secondary-color-h), var(--secondary-color-s), var(--secondary-color-l), .1); */
  padding: 15px;
  border-radius: 5px;
}
li {
  font-family: var(--font-title);
  display: flex;
  align-items: center;
  margin: 0;
}
.contact-list svg {
  margin-right: 15px;
}
.contact-list a {
  text-decoration: none;
}
.main-section {
  margin-top: 48px;
  margin-bottom: 48px;
}
</style>
